import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { executeCallback } from '../../../models/Callback';
import { selectTokenConfig } from '../../../redux/token/tokenSelectors';
import { useSelector } from 'react-redux';

export function useSkipReview(): {
  handleLogoClick: ((e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void) | undefined;
  handleSkipClick: () => void;
} {
  const history = useHistory();
  const config = useSelector(selectTokenConfig);
  const { t } = useTranslation();
  const skipReview = () => executeCallback(history, config?.splash?.onSkip);
  const handleLogoClick: ((e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void) | undefined =
    config?.splash?.skip && config.splash.skip !== 'disabled'
      ? (e) => {
          if (e.detail > 3 && window.confirm(t('skipReview'))) {
            skipReview();
          }
        }
      : undefined;

  return { handleLogoClick, handleSkipClick: skipReview };
}
