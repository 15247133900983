import * as H from 'history';

export interface UrlCallback {
  url: string;
  delay?: number;
}

export interface PostMessageCallback {
  postMessage: string | object;
}

export type Callback = UrlCallback | PostMessageCallback;

export function isUrlCallback(callback: Callback): callback is UrlCallback {
  return !!(callback as UrlCallback).url;
}

export function isPostMessageCallback(callback: Callback): callback is PostMessageCallback {
  return !!(callback as PostMessageCallback).postMessage;
}

export function executeCallback(
  history: H.History,
  callback?: Callback
): { stopExecution: boolean; cancel?: () => void; hurry?: () => void } {
  if (callback) {
    if (isUrlCallback(callback)) {
      const goTo = () => {
        if (callback.url.startsWith('/')) {
          history.push(callback.url);
        } else {
          window.location.href = callback.url;
        }
      };
      const timeout = setTimeout(goTo, callback.delay || 0);
      const cancel = () => timeout && clearTimeout(timeout);
      return {
        stopExecution: !callback.delay,
        cancel,
        hurry: () => {
          if (timeout) {
            clearTimeout(timeout);
            goTo();
          }
        },
      };
    } else if (isPostMessageCallback(callback)) {
      window.parent.postMessage(callback.postMessage, '*');
    }
  }
  return { stopExecution: false };
}
