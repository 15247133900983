import useSimpleToaster, { createToast } from '@yumpingo/yummy-components/hooks/useSimpleToaster';
import { asyncEffect } from '@yumpingo/yummy-components/utils/asyncEffect';
import { handleError as handleErrorGlobal } from '@yumpingo/yummy-components/utils/error-handler';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { missingItemsPath } from '../../../components/routing/app-routes/components/review-routes/ReviewRoutes';
import { REVIEW_ROUTES } from '../../../components/routing/app-routes/components/review-routes/ReviewRoutes.config';
import { useReviewConfigFood } from '../../../hooks/useReviewConfigFood';
import { executeCallback } from '../../../models/Callback';
import { selectMissingItemsQueryState } from '../../../redux/missingItems/missingItemsStateSelectors';
import { setMissingItemsQuery } from '../../../redux/missingItems/missingItemsStateSlice';
import {
  selectReviewConfigComplaint,
  selectReviewConfigFlow,
  selectReviewConfigService,
  selectReviewConfigSubscribe,
  selectReviewConfigSubscribeIntegration,
} from '../../../redux/reviewConfig/reviewConfigSelectors';
import { selectSessionState } from '../../../redux/sessionState/sessionSelectors';
import { selectTokenOnComplete, selectTokenOnError } from '../../../redux/token/tokenSelectors';
import { useUpdateSession } from './use-update-session';
import { getBillItemInfo } from '../../../utils/digi-bill';

type ReviewStep = keyof typeof REVIEW_ROUTES;

export function useNextStep(current: ReviewStep): { nextStep: () => void; loading: boolean } {
  const history = useHistory();
  const onComplete = useSelector(selectTokenOnComplete);
  const onError = useSelector(selectTokenOnError);
  const reviewConfigService = useSelector(selectReviewConfigService);
  const reviewConfigComplaint = useSelector(selectReviewConfigComplaint);
  const reviewConfigSubscribe = useSelector(selectReviewConfigSubscribe);
  const subscribeIntegrations = useSelector(selectReviewConfigSubscribeIntegration);
  const { begin, sendToApi } = useUpdateSession();
  const { addToast } = useSimpleToaster();
  const [readyToSubscribe, setReadyToSubscribe] = useState(false);
  const [readyToFinish, setReadyToFinish] = useState(false);
  const { t } = useTranslation();
  const session = useSelector(selectSessionState);
  const flow = useSelector(selectReviewConfigFlow);
  const dispatch = useDispatch();

  const missingItemsInProgress = useSelector(selectMissingItemsQueryState);

  // Subscribe step effect
  useEffect(() => {
    if (readyToSubscribe) {
      if (reviewConfigSubscribe?.captureEmail) {
        return asyncEffect(async (isCancelled) => {
          try {
            await sendToApi(false);
            if (!isCancelled()) {
              history.push(REVIEW_ROUTES.subscribe);
            }
          } catch (error) {
            handleApiError(error);
          }
        });
      } else {
        finish();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSubscribe]);

  // Finish step effect
  useEffect(() => {
    if (readyToFinish) {
      sendToApi(true)
        .then(() => {
          const callbackResponse = executeCallback(history, onComplete);
          if (!callbackResponse.stopExecution) {
            addToast(
              createToast(
                t('reviewSubmitted'),
                'success',
                {
                  onClick: callbackResponse.hurry || (() => {}),
                },
                1500
              )
            );
            history.push(REVIEW_ROUTES.final);
          }
        })
        .catch(handleApiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToFinish]);

  const handleApiError = (error: any) => {
    handleErrorGlobal(error);
    setReadyToSubscribe(false);
    setReadyToFinish(false);
    if (!executeCallback(history, onError).stopExecution) {
      addToast(createToast(t('unableToSendReview'), 'error', { text: 'Retry', onClick: subscribe }));
    }
  };

  const beforeSubscribe = () => {
    // if subscribeIntegrations has a value, we don't want to show the subscribe page as we need to show a custom subscribe page.
    // In this case we also disregard the complaint configuration.
    if (subscribeIntegrations) {
      switch (subscribeIntegrations) {
        case 'punchhLoyalty':
        case 'punchhEClub':
        case 'punchhLoyaltyWithEClub':
          history.push(REVIEW_ROUTES.punchhSubscribe);
          return;
      }
    }

    //@ts-ignore - a.answer! <= 3, Operator '<=' cannot be applied to types 'string | number' and 'number'
    reviewConfigComplaint?.captureEmail && session?.answers?.find((a) => a.isCore && a.answer! <= 3)
      ? history.push(REVIEW_ROUTES.complaint)
      : subscribe();
  };

  const subscribe = async () => {
    // This is an awful hack to get around the fact that recoil state is not available
    // in the next render: https://github.com/facebookexperimental/Recoil/issues/1076
    await new Promise((resolve) => setTimeout(resolve, 0));
    setReadyToSubscribe(true);
  };

  const finish = async () => {
    // This is an awful hack to get around the fact that recoil state is not available
    // in the next render: https://github.com/facebookexperimental/Recoil/issues/1076
    await new Promise((resolve) => setTimeout(resolve, 0));
    setReadyToFinish(true);
  };

  const foodConfig = useReviewConfigFood();

  const nextStep = async () => {
    switch (current) {
      case 'splash': {
        begin();
        // go to service review if flow is experienceOnly or if service.showFirst flag is true
        if (flow === 'experienceOnly' || reviewConfigService?.showFirst) {
          // core question is always the first question
          const coreQuestionId = reviewConfigService.questions[0].id;
          history.push(REVIEW_ROUTES.service.replace(':questionId', coreQuestionId));
        } else {
          const { billItemPath, isBillTwoItemsOrLess } = getBillItemInfo(foodConfig.menu);

          isBillTwoItemsOrLess
            ? history.push(billItemPath)
            : history.push(REVIEW_ROUTES.food + history.location.search);
        }
        break;
      }
      case 'food': {
        if (missingItemsInProgress) {
          const urlQuery = missingItemsInProgress;
          history.push(REVIEW_ROUTES.service + urlQuery);
          dispatch(setMissingItemsQuery(''));
        } else if (history.location.pathname.includes(missingItemsPath)) {
          history.push(REVIEW_ROUTES.food + history.location.search);
        } else if (reviewConfigService?.showFirst) {
          beforeSubscribe();
        } else {
          const coreQuestionId = reviewConfigService.questions?.find((q) => q.isCore)?.id!;
          history.push(REVIEW_ROUTES.service.replace(':questionId', coreQuestionId));
        }
        break;
      }
      case 'service': {
        // experienceOnly does not allow for food reviews. Although the showFirst should be false, we still want to make sure
        if (reviewConfigService?.showFirst && flow !== 'experienceOnly') {
          const { billItemPath, isBillTwoItemsOrLess } = getBillItemInfo(foodConfig.menu);

          isBillTwoItemsOrLess ? history.push(billItemPath) : history.push(REVIEW_ROUTES.food);
        } else {
          beforeSubscribe();
        }
        break;
      }
      case 'subscribe':
      case 'punchhSubscribe':
      case 'complaint': {
        finish();
        break;
      }
    }
  };

  return { nextStep, loading: readyToSubscribe || readyToFinish };
}
